import { vuex_subscribe_module, vuex_store_config } from '@/vuex'
import { load_state_from_local_storage } from '@/resources'

const vuex_config_object = {
  location: {
    label: '',
    latitude: '',
    longitude: '',
    matchLevel: '',
  },
  checkin: '',
  checkout: '',
  hotels: [],
  selected_hotel: {},
  hotel_data: [],
  current_search: '',
  room_selection: {},
  selected_solution: {},
  last_search: '',
  trigger_search: false,
  // filtri ricerca
  filter_availability: false,
  filter_refundable: false,
  filter_stars: [],
  filter_amenities: [],
  filter_hotel_categories: [],
  filter_price_range: {
    min: 0,
    max: 0,
  },
  filter_review_score: 0,
  filter_preferred: 0,
  // ordinamento risultati
  sort_by: '',
}

export const serialized_vuex_config_object = JSON.stringify(vuex_config_object)

const state_config = vuex_store_config(vuex_config_object)

let name = 'livebk',
  state = state_config.state,
  module = {
    namespaced: true,
    state: load_state_from_local_storage(name, state_config.state),
    mutations: state_config.mutations,
    getters: state_config.getters,
    actions: state_config.actions,
    get_local_storage_submodule() {
      // dichiaro le chiavi che verranno scritte nel local storage
      return {
        location: state_config.state.location,
        checkin: state_config.state.checkin,
        checkout: state_config.state.checkout,
        last_search: state_config.state.last_search,
        current_search: state_config.state.current_search,
        room_selection: state_config.state.room_selection,
        selected_solution: state_config.state.selected_solution,
        selected_hotel: state_config.state.selected_hotel,
        hotel_data: state_config.state.hotel_data,
        filter_availability: state_config.state.filter_availability,
        filter_refundable: state_config.state.filter_refundable,
        filter_stars: state_config.state.filter_stars,
        filter_amenities: state_config.state.filter_amenities,
        filter_hotel_categories: state_config.state.filter_hotel_categories,
        filter_review_score: state_config.state.filter_review_score,
        filter_preferred: state_config.state.filter_preferred,
        sort_by: state_config.state.sort_by,
      }
    },
  }

export function register_state_module(prototype) {
  if (prototype.$submodules == undefined) prototype.$submodules = {}
  prototype.$submodules[name] = {
    get_local_storage_submodule: module.get_local_storage_submodule()
  }
  vuex_subscribe_module(name, state, module)
}