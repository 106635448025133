import Vue from 'vue'
import Mixins from '@booking/utilities/mixins'
import BookingEngine from '@booking/components/blocks/booking-engine.vue'
import Loader from '@components/blocks/loader.vue'
import SentryInit from '@src/sentry'

// site specific components and settings
export default function site_config() {
  Vue.component('booking-engine', BookingEngine)
  Vue.component('loader', Loader)
  Vue.mixin(Mixins)
  SentryInit()
}