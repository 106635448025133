
const prod = [
  {
    path: '/',
    name: 'homepage',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/homepage.vue').then(Homepage => {
      return Homepage
    }),
  },
  {
    path: '/results',
    name: 'results',
    props: true,
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/results').then(Results => {
      return Results
    }),
  },
  {
    path: '/detail',
    name: 'detail',
    props: true,
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/detail').then(Detail => {
      return Detail
    }),
  },
  {
    path: '/checkout',
    name: 'checkout',
    props: true,
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/checkout').then(Checkout => {
      return Checkout
    }),
  },
  {
    path: '/confirm/:confirm_code/:reservation_id',
    name: 'confirm',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/confirm').then(function (Confirm) {
      if (process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm View loaded')
      return Confirm
    }),
    props: (route) => {
      return {
        confirm_code: route.params.confirm_code,
        reservation_id: route.params.reservation_id,
        ignore_redirect: false,
      }
    },
  },
  {
    path: '/prenotazioni/:confirm_code/:reservation_id',
    name: 'prenotazioni',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/confirm').then(function (Confirm) {
      if (process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm View loaded')
      return Confirm
    }),
    props: (route) => {
      return {
        confirm_code: route.params.confirm_code,
        reservation_id: route.params.reservation_id,
        ignore_redirect: true,
      }
    },
  },
  {
    path: '/doublecheck/:confirmcode/:reservation_id',
    name: 'doublecheck',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/doublecheck').then(function (Doublecheck) {
      if (process.env.VUE_APP_MODE != 'production') window.console.log('async: Doublecheck View loaded')
      return Doublecheck
    }),
    props: (route) => {
      return {
        confirmcode: route.params.confirmcode,
        reservation_id: route.params.reservation_id,
      }
    },
  },
  {
    path: '/termini-e-condizioni',
    name: 'terms',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/terms').then(function (Terms) {
      return Terms
    }),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/about.vue').then(function (About) {
      return About
    }),
  },
  {
    path: '/prenota-un-viaggio',
    name: 'prenota-un-viaggio',
    component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/prenota-un-viaggio.vue').then(function (PrenotaUnViaggio) {
      return PrenotaUnViaggio
    }),
  },
]

export {
  prod
}
