import Vue from 'vue'
import Router from '@router/router'
import { log } from '@utilities'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

const env = process.env.VUE_APP_MODE
const sentry_dsn = process.env.VUE_APP_SENTRY_DSN
const sentry_trace_sample_rate = (env == 'production' ? 0.2 : 1.0)
const sentry_debug = (env !== 'production') // vedo il log verboose di sentry in staging
const sentry_log_errors = (env !== 'production') // se/quando vedere gli errori in console browser
const sentry_trace_origins = [
  "localhost",
  "dev.bk.vadobay.com",
  "stag.booking.vadobay.com",
  "booking.vadobay.com",
  //"dev.vacanze.vadobay",
  /^\//
]  // origins da tracciare. possono essere, eventualmente, sovrascritte dal portale passandole come argomento alla SentryInit() leggendole da env, da conf o anche eventualmente hardcodandole portale per portale

export default function () {
  log('[sentry.js] - sentry init')
  if ((env == 'staging' || env == 'production') && sentry_dsn) {
    Sentry.init({
      Vue,
      environment: env,
      dsn: sentry_dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
          tracingOrigins: sentry_trace_origins,
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: sentry_trace_sample_rate,
      debug: sentry_debug,
      tracingOptions: {
        trackComponents: true,
      },

      // Vue specific
      trackComponents: [],
      hooks: [],
      logErrors: sentry_log_errors,
      attachProps: true,
      attachStacktrace: true,

      // Session Replay
      replaysSessionSampleRate: (env !== 'production')? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}

/* TODO
 - [gestione sourcemaps / stacktrace]: serve installare npx @sentry/wizard@latest -i sourcemaps => RICHIEDE VERSIONE NODE SUPERIORE ALLA NOSTRA: rimandiamo
 - [gestione versione]: per ora default (come versione sentry traccia l'hash del commit, che è anche la soluzione raccomandata; in alternativa si dovrebbe allineare al semver del package.json, ma la conf non funziona a dovere)
*/